// src/components/Navbar.js
import React, { useState } from 'react';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Ensure correct import

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
  };

  return (
    <header>
      <nav className="navbar">
        <Link to="/" className="navbar-logo">I M P U L S E</Link> {/* Use Link instead of <a> */}
        <ul
          className={`navbar-links ${isMobile ? 'navbar-links-mobile' : ''}`}
          onClick={isMobile ? handleToggle : undefined}
        >
          <li>
            <Link to="/" onClick={closeMobileMenu}>Home</Link>
          </li>
          <li>
            <Link to="https://impulse-website-product-catalogue.s3.ap-south-1.amazonaws.com/impulse+2024.pdf" onClick={closeMobileMenu}>Product Catalog</Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMobileMenu}>Contact</Link>
          </li>
        </ul>
        <div
          className="mobile-menu-icon"
          onClick={handleToggle}
          aria-label="Toggle navigation menu"
          aria-expanded={isMobile}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => { if (e.key === 'Enter') handleToggle(); }}
        >
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      {/* Optional Overlay */}
      {isMobile && <div className="overlay active" onClick={closeMobileMenu}></div>}
    </header>
  );
};

export default Navbar;
