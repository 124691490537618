// src/components/ContactInfo.js
import React from 'react';
import './ContactInfo.css';

const ContactInfo = () => {
  return (
    <section className="contact-info" id="contact-info">
      <h2>Contact Us</h2>
      <p>Email: admin@professionalsupplies.in</p>
      <p>Phone: +91 99695-56677</p>
      <p>+91 99698-89900</p>
    </section>
  );
};

export default ContactInfo;
