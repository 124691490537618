// src/components/MainContent.js
import React from 'react';
import './MainContent.css';
import ContactInfo from './ContactInfo';
import Button from './Button';

const MainContent = () => {
  return (
    <main className="container main-content">
      <h1>Our Website is Coming Soon!</h1>
      <p>Stay Tuned!</p>
      
      <ContactInfo />
      
      <Button />
    </main>
  );
};

export default MainContent;
