// src/components/Button.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

const Button = () => {
  return (
    <Link to="https://impulse-website-product-catalogue.s3.ap-south-1.amazonaws.com/impulse+2024.pdf" className="button" id="product-catalog">
      View Our Product Catalog
    </Link>
  );
};

export default Button;
